import React from 'react';
import Layout from '../layouts/Layout';
import HeroBanner from '../components/HeroBanner/HeroBanner';
// @ts-ignore

import arrow from '../images/white-right-arrow.svg';
// @ts-ignore
import arrowBlue from '../images/blue-right-arrow.svg';

import './jobDescription.css';

const JobDescription = ({ pageContext }: any) => {
	return (
		<Layout
			title={'On the Run | Employment Opportunities'}
			desc={'On the Run | Employment Opportunities'}
		>
			<HeroBanner />
			<section>
				<div className="container mx-auto my-0 w-full ">
					<div className="w-full px-[16px]">
						<div className="w-full pt-[64px] pb-[80px] text-left max-md:pb-[40px] max-md:pt-[32px]">
							<a
								href="/search"
								className="text-dirtcheaptext hover:text-dirtcheaptext max-w-[100px] text-[20px]"
							>
								<button className="flex flex-row items-center">
									<img
										src={arrowBlue}
										width={16}
										className="mr-[6px] rotate-180"
									/>
									Back
								</button>
							</a>
							<h2 className="font-dinblack text-[48px] font-bold uppercase text-[#0661BB] max-md:text-[30px]">
								{pageContext.title}
							</h2>
						</div>
					</div>
					<div className="flex flex-row px-[16px] pb-[160px] max-lg:flex-col-reverse">
						<div className="flex w-3/5 w-2/3 flex-col items-start justify-start text-[16px] max-lg:w-full">
							<h3 className="font-open-sans text-[30px] text-[#0661BB]">
								JOB DESCRIPTION
							</h3>

							<div className="pb-[64px]">
								<p
									dangerouslySetInnerHTML={{
										__html: pageContext.description
									}}
								/>
							</div>

							<a
								className=""
								href={pageContext.url}
								target={'_blank'}
							>
								<button className="mx-auto flex h-[49px] w-full w-[262px] flex-row items-center justify-end bg-[#0091FE] font-open-sans text-[22px] font-bold uppercase leading-[28px] text-white">
									<span className="pr-[40px]">APPLY NOW</span>{' '}
									<div className="m-0 flex h-[49px] w-[49px] flex-col items-center justify-center bg-[#037EDC] p-0">
										<img
											src={arrow}
											alt=""
											width={24.89}
											height={21}
										/>
									</div>
								</button>
							</a>
						</div>
						<div className="ml-[16px] flex w-1/3 flex-col items-start justify-start max-lg:ml-0 max-lg:mb-[24px] max-lg:w-full">
							<div className="w-full border-t-4 border-[#9DC435] bg-[#F4F4F4] px-[24px] py-[36px]">
								<p className="c-[#333333] pb-[20px] font-open-sans text-[18px] font-bold uppercase leading-[28px] max-lg:pb-[8px]">
									Location
								</p>
								<p className="c-[#333333] pb-[20px] font-open-sans text-[18px] leading-[28px] max-lg:pb-[8px]">
									{pageContext.fullAddress}
								</p>
								<p className="c-[#333333] pb-[20px] font-open-sans text-[18px] font-bold uppercase leading-[28px] max-lg:pb-[8px]">
									POSTED
								</p>
								<p className="c-[#333333] pb-[20px] font-open-sans text-[18px] leading-[28px] max-lg:pb-[8px]">
									{pageContext.postingDate}
								</p>
								<div className="w-full pt-[28px] text-center max-lg:text-left">
									<a
										className=""
										href={pageContext.url}
										target={'_blank'}
									>
										<button className="mx-auto flex h-[49px] w-full w-[262px] flex-row items-center justify-end bg-[#0091FE] font-open-sans text-[22px] font-bold uppercase leading-[28px] text-white">
											<span className="pr-[40px]">
												APPLY NOW
											</span>{' '}
											<div className="m-0 flex h-[49px] w-[49px] flex-col items-center justify-center bg-[#037EDC] p-0">
												<img
													src={arrow}
													alt=""
													width={24.89}
													height={21}
												/>
											</div>
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
